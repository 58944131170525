<template>
  <div
    v-loading="loading"
    element-loading-text="Please wait..."
    style="min-width: 290px; min-height: 50px"
  >
    <template v-if="viewDataOnly || viewDataAndAdd">
      <el-row
        v-if="currentEntity && currentEntity.name && viewDataAndAdd"
        class="mt-1"
      >
        <el-col :span="5" :offset="18" class="column-with-text">
          <span>Add {{ currentEntity.name }}</span>
        </el-col>
        <el-col :span="1">
          <quickEntityAddWidget
            v-if="viewDataAndAdd"
            :currentEntity="currentEntity"
            :openNewAdd="allowNewAddOnly"
            :actionButtonField="actionButtonField"
            :relationshipDetails="relationshipDetails"
            :detailed_add="true"
            @onNewEntity="setQuickAddEntityDataAsValue"
            @quickAddClose="quickAddClose"
            :parentFields="parentFields"
            :parentForm="parentForm"
            :parentEntityData="parentEntityData"
          ></quickEntityAddWidget>
        </el-col>
      </el-row>
      <EntityReationshipDataTable
        v-if="
          relationshipDetails &&
          relationshipDetails.parent_entity_id &&
          relationshipDetails.parent_entity_data_id &&
          openRelationalModal
        "
        :relationshipDetails="relationshipDetails"
        :nextRealationshipDetails="null"
        :hideLabel="true"
        :hideActions="true"
        :currentEntityData="{}"
        :addDataBoardView="true"
      />
    </template>
    <template v-else>
      <el-row :gutter="20">
        <el-col :span="22">
          <span v-if="currentEntity && currentEntity.name">{{
            currentEntity.name
          }}</span>
        </el-col>
        <el-col
          :span="2"
          v-if="(allowNewAdd || allowNewAddOnly) && currentEntity"
        >
          <quickEntityAddWidget
            :currentEntity="currentEntity"
            :openNewAdd="allowNewAddOnly"
            :actionButtonField="actionButtonField"
            :relationshipDetails="relationshipDetails"
            :detailed_add="true"
            @onNewEntity="setQuickAddEntityDataAsValue"
            @quickAddClose="quickAddClose"
            :parentFields="parentFields"
            :parentForm="parentForm"
            :parentEntityData="parentEntityData"
          ></quickEntityAddWidget>
        </el-col>
      </el-row>
      <el-select
        v-if="!allowNewAddOnly"
        v-model="selectedEntity"
        @change="updateEntityData"
        clearable
        filterable
        multiple
        collapse-tags
        placeholder="Select data"
        style="width: 100%"
        :multiple-limit="relationshipDetails.allowOne ? 1 : 0"
      >
        <el-option
          v-for="(temp, i) in allEntityDataList"
          :key="temp._id + '_' + i"
          :value="temp._id"
          :label="getLabel(temp, i)"
          >{{ getLabel(temp, i) }}
        </el-option>
      </el-select>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: [
    "openRelationalModal",
    "entityId",
    "relationshipDetails",
    "allowNewAdd",
    "allowNewAddOnly",
    "actionButtonField",
    "viewDataOnly",
    "parentForm",
    "parentFields",
    "viewDataAndAdd",
    "parentEntityData",
  ],
  computed: {
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityById",
      "getEntityRecordsForTable",
      "getEntitiesDataErrors",
      "getEntityPrimaryData",
    ]),
    ...mapGetters("entityRelationships", ["getParentEntityRelatiponshipData"]),
  },
  components: {
    quickEntityAddWidget: () =>
      import("@/components/widgets/quickEntityAddWidget"),
    EntityReationshipDataTable: () =>
      import("@/components/entity/EntityReationshipDataTable.vue"),
  },
  watch: {},
  data() {
    return {
      currentEntity: null,
      loading: false,
      allEntityDataList: [],
      selected_filter: "",
      selectedFileds: {},
      selectedEntity: "",
      realtionshipFilter: null,
      existedDataIds: [],
      removableFieldsKeys: [],
    };
  },
  async mounted() {
    if (this.relationshipDetails) {
      this.setCurrentEntityFilters();
      await this.getExistedRelationshipsData();
    }
    await this.fetchEntityById(this.entityId);
  },
  methods: {
    async getExistedRelationshipsData() {
      let params = {
        parent_entity_id: this.relationshipDetails.parent_entity_id,
        parent_entity_data_id: this.relationshipDetails.parent_entity_data_id,
        child_entity_id: this.relationshipDetails.child_entity_id,
      };

      if (this.relationshipDetails.owner_type == "CHILD") {
        params = {
          parent_entity_id: this.relationshipDetails.child_entity_id,
          parent_entity_data_id: this.relationshipDetails.parent_entity_data_id,
          child_entity_id: this.relationshipDetails.parent_entity_id,
        };
      }
      await this.$store.dispatch(
        "entityRelationships/fetchParentEntityRelationshipData",
        params
      );
      if (
        this.getParentEntityRelatiponshipData &&
        this.getParentEntityRelatiponshipData.child_entity_data_ids &&
        this.getParentEntityRelatiponshipData.child_entity_data_ids.length
      ) {
        this.existedDataIds =
          this.getParentEntityRelatiponshipData.child_entity_data_ids;
      }
    },
    setCurrentEntityFilters() {
      if (
        this.relationshipDetails &&
        this.relationshipDetails.child_relation_type == "ONE" &&
        this.relationshipDetails.parent_relation_type == "ONE"
      ) {
        if (this.relationshipDetails.owner_type == "PARENT") {
          this.realtionshipFilter = {
            parent_entity_id: this.relationshipDetails.child_entity_id,
            child_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_id: this.relationshipDetails.parent_entity_id,
            relation_type: "ONE_ONE",
          };
        } else {
          this.realtionshipFilter = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            relation_type: "ONE_ONE",
          };
        }
      }

      if (
        this.relationshipDetails.owner_type == "PARENT" &&
        this.relationshipDetails.parent_relation_type == "MANY" &&
        this.relationshipDetails.child_relation_type == "ONE"
      ) {
        this.realtionshipFilter = null;
      }

      if (
        this.relationshipDetails.owner_type == "PARENT" &&
        this.relationshipDetails.parent_relation_type == "ONE" &&
        this.relationshipDetails.child_relation_type == "MANY"
      ) {
        this.realtionshipFilter = {
          parent_entity_id: this.relationshipDetails.child_entity_id,
          child_entity_data_id: this.relationshipDetails.parent_entity_data_id,
          child_entity_id: this.relationshipDetails.parent_entity_id,
          relation_type: "MANY_ONE",
        };
      }

      if (
        this.relationshipDetails.owner_type == "PARENT" &&
        this.relationshipDetails.parent_relation_type == "MANY" &&
        this.relationshipDetails.child_relation_type == "ONE"
      ) {
        this.realtionshipFilter = null;
      }

      if (
        this.relationshipDetails.owner_type == "CHILD" &&
        this.relationshipDetails.parent_relation_type == "MANY" &&
        this.relationshipDetails.child_relation_type == "ONE"
      ) {
        this.realtionshipFilter = {
          parent_entity_id: this.relationshipDetails.parent_entity_id,
          child_entity_data_id: this.relationshipDetails.parent_entity_data_id,
          child_entity_id: this.relationshipDetails.child_entity_id,
          relation_type: "MANY_ONE",
        };
      }

      if (
        this.relationshipDetails.owner_type == "CHILD" &&
        this.relationshipDetails.parent_relation_type == "ONE" &&
        this.relationshipDetails.child_relation_type == "MANY"
      ) {
        this.realtionshipFilter = null;
      }
    },
    quickAddClose(refresh = true) {
      this.$emit("quickAddClose", refresh);
    },
    async setQuickAddEntityDataAsValue(entityData) {
      if (!this.allowNewAddOnly) {
        await this.fetchEntitiesDataForTable(this.currentEntity._id);
      }
      this.selectedEntity = [
        ...this.selectedEntity,
        ...[entityData.entity_data_id],
      ];
      this.updateEntityData(this.selectedEntity);
    },
    updateEntityData(value) {
      this.$emit("entityDataSelect", {
        value,
        update: this.allowNewAddOnly || this.viewDataAndAdd,
        unAssignedFieldDetails: this.removableFieldsKeys,
      });
    },
    async fetchEntityById(entityId) {
      this.loading = true;
      await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
        entity_id: entityId,
      });
      if (this.getEntityById) {
        this.currentEntity = this.getEntityById;
        if (this.actionButtonField.parent_entityId) {
          this.setRelationalFields();
        }
        if (!this.allowNewAddOnly) {
          this.fetchEntitiesDataForTable(entityId);
        } else {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    async setRelationalFields() {
      await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
        entity_id: this.actionButtonField.parent_entityId,
      });
      if (this.getEntityById) {
        let parentEntity = { ...this.getEntityById };
        this.currentEntity.templates.map((data) => {
          if (
            data.templateInfo &&
            data.templateInfo.sections &&
            data.templateInfo.sections.length
          ) {
            data.templateInfo.sections[0].fields.map((field) => {
              if (this.relationshipDetails.representation == "FIELD") {
                if (
                  field.input_type == "ENTITY" &&
                  this.relationshipDetails.child_entity_id == field.entity_id
                ) {
                  this.removableFieldsKeys = [
                    {
                      key: field.key,
                      template_id: data.template_id,
                      value: this.relationshipDetails.parent_entity_data_id,
                    },
                    {
                      key: field.key + "/name",
                      template_id: data.template_id,
                      value: this.entityDataTitle(parentEntity),
                    },
                  ];
                }
              }
            });
          }
        });
      }
    },
    getPrimaryName(primaryField, item) {
      let name = "";
      let key =
        primaryField && primaryField["key"] ? primaryField["key"] : null;
      if (key) {
        if (key.includes("#")) {
          key = key.split("#")[1];
        }
        name =
          item &&
          item.entityData &&
          primaryField.template_id &&
          item.entityData[primaryField.template_id] &&
          item.entityData[primaryField.template_id][key]
            ? item.entityData[primaryField.template_id][key + "/name"] ||
              item.entityData[primaryField.template_id][key]
            : "-";
        if (
          !name &&
          item &&
          item.entity_prime_data &&
          item.entity_prime_data[key]
        ) {
          name =
            item.entity_prime_data[key + "/name"] ||
            item.entity_prime_data[key];
        }
        if (
          primaryField?.inputType == "WEEKDAYS" &&
          this.weekDayMap &&
          this.weekDayMap[name]
        ) {
          name = this.weekDayMap[name];
        }
      }
      if (name && typeof name == "object" && name.length) {
        return name.join(",");
      }
      return name;
    },
    entityDataTitle(currentEntity) {
      let name = "";
      if (this.getEntityPrimaryData) {
        let pr =
          currentEntity &&
          currentEntity.primaryFields &&
          currentEntity.primaryFields[0]
            ? currentEntity.primaryFields[0]
            : null;
        if (pr) {
          let obj = {},
            dt = {};
          (this.getEntityPrimaryData.templates_data || []).forEach((te) => {
            dt[te.template_id] = te?.template_data_id?.template_data;
          });
          obj = { ...this.getEntityPrimaryData, ...{ ["entityData"]: dt } };
          name = this.getPrimaryName(pr, obj);
        }
      }
      return name;
    },
    async fetchEntitiesDataForTable(entityId) {
      this.loading = true;
      let params = {
        entity_id: entityId,
        template_fields_data: this.currentEntity.templates.map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: [],
      };
      if (this.realtionshipFilter && this.realtionshipFilter.parent_entity_id) {
        params.relationship = this.realtionshipFilter;
      }
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      if (this.getEntityRecordsForTable) {
        this.allEntityDataList = this.getEntityRecordsForTable.data.filter(
          (e) => this.existedDataIds.indexOf(e._id) == -1
        );
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    getLabel(item, i) {
      let selectedEntity = this.currentEntity ? this.currentEntity : {};
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (item.entity_prime_data && item.entity_prime_data[key]) {
            name = item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id] &&
              item.entityData[primaryField.template_id][key]
                ? item.entityData[primaryField.template_id][key]
                : "data" + i;
          }
        }
      }
      return name;
    },
  },
};
</script>
<style scoped>
.column-with-text {
  display: flex;
  justify-content: flex-end;
  color: #007bff;
}
</style>
